.hr-line{
    border-bottom: 3px solid orange;
    display: inline-block;
    
  }

.custom-card{
    border: 2px orange solid;
 }
.custom-card:hover {
   box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
 }

 .h1-border__style{
  border-bottom: 3px #ffa500 solid;
}