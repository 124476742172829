
  .hr-line{
    display: inline-block;
    border-bottom: 3px solid #ffa500;
  }
  .img-border{
    border: 2px orange solid;
  }
  .custom-card{
    border: 3px orange solid;
    color: white;
    }
  .custom-card:hover, .img-border:hover {
    box-shadow: 0 8px 16px 0 rgb(255, 165, 0);
  }

  .text-justify{
    text-align: justify; text-justify: inter-word;
  }




