.jumbotron{
    /*background-image: url("../../assets/images/slide-2.jpg");*/
    background-color: rgb(13,110,253);
    border-top: 2px white solid;
}

.btn-orange {
    background-color: orange;
    color: white;
}

.btn-orange:hover
 {
    color: white;
    background-color: black;
    border-color: orange;
}

.btn-orange:focus{
    color: white;
    background-color: black;
}
