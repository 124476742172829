.jumbotron-section{
    background-color: rgb(13,110,253);
    border-top: 2px white solid;
}

.experties-section{
    background-color: #e5eef6;
    padding: 30px;
    
}

.about-section{
 padding: 30px;   
 background: rgba(40, 39, 39);
}

.contact-section{
    background-color: #e5eef6;
    padding: 30px;
}

.footer-section{
    background-color: rgb(40, 39, 39);
}

.footer-section__copyright{
    background-color: orange;
   
    
}